/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    code: "code",
    h3: "h3",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "The Slide sub-component handles each slide.\nUse the Slides component to interact this because the ", React.createElement(_components.code, null, "Components"), " collection does not contain this component."), "\n", React.createElement(_components.h2, {
    id: "properties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#properties",
    "aria-label": "properties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Properties"), "\n", React.createElement(_components.h3, {
    id: "index",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#index",
    "aria-label": "index permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "index")), "\n", React.createElement(Type, {
    code: 'index: number'
  }), "\n", React.createElement(_components.p, null, "The index of the slide. This can be negative or greater than the number of slides for clones."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slideindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slideindex",
    "aria-label": "slideindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slideIndex")), "\n", React.createElement(Type, {
    code: 'slideIndex: number'
  }), "\n", React.createElement(_components.p, null, "The slide index where the clone corresponds. This is ", React.createElement(_components.code, null, "-1"), " for normal slides."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slide",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slide",
    "aria-label": "slide permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slide")), "\n", React.createElement(Type, {
    code: 'slide: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "The slide element."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "container",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#container",
    "aria-label": "container permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "container")), "\n", React.createElement(Type, {
    code: 'container: HTMLElement'
  }), "\n", React.createElement(_components.p, null, "The container element if available, or otherwise ", React.createElement(_components.code, null, "undefined"), "."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "isclone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#isclone",
    "aria-label": "isclone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "isClone")), "\n", React.createElement(Type, {
    code: 'isClone: boolean'
  }), "\n", React.createElement(_components.p, null, "Indicates whether the component contains a cloned slide or not."), "\n", React.createElement(_components.h2, {
    id: "methods",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#methods",
    "aria-label": "methods permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Methods"), "\n", React.createElement(_components.h3, {
    id: "style",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#style",
    "aria-label": "style permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "style()")), "\n", React.createElement(Type, {
    code: 'style( prop: string, value: string | number, useContainer?: boolean ): void'
  }), "\n", React.createElement(_components.p, null, "Adds a CSS rule to the slide or the container."), "\n", React.createElement(Params, {
    params: [['prop', 'A property name.'], ['value', 'A CSS value to add.'], ['useContainer', 'Optional. Determines whether to apply the style to the container or not.']]
  }), "\n", React.createElement(_components.h3, {
    id: "iswithin",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#iswithin",
    "aria-label": "iswithin permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "isWithin()")), "\n", React.createElement(Type, {
    code: 'isWithin( from: number, distance: number ): boolean'
  }), "\n", React.createElement(_components.p, null, "Calculates how far this slide is from another slide and\nreturns ", React.createElement(_components.code, null, "true"), " if the distance is within the given ", React.createElement(_components.code, null, "distance"), "."), "\n", React.createElement(Params, {
    params: [['from', 'An index of a base slide.'], ['distance', 'Distance to test. The method returns `true` if the slide is within this number.']],
    ret: '`true` if the slide is within the `distance` from the base slide, or otherwise `false`.'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
